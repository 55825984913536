
import monaco from 'monaco-js';
import {getLangFromExtension} from 'o365.vue.modules.monacoHelpers.ts';

let control:MonacoControl|null = null;
let models:Map<string,any> = new Map();
let notKeepableModels:Map<string,any> = new Map();
let modelStates:Map<string,any> = new Map();
let currentKey: string|null = null;

export default function getMonaco(pOptions:any){
    if(!pOptions.element) return;
    

    if(control && !pOptions.element.isEqualNode(control.element)){
        if(control) control.dispose();
        control = null;
    }

    if (!control) {
        control = new MonacoControl(pOptions);
    } else {
        control.readOnly = pOptions.readonly ?? false;
        control.editor.updateOptions({
            readOnly: control.readOnly
        });
        
    }

    if(pOptions.options){
         control.editor.updateOptions(pOptions.options);
    }
   
  
    const vKey = pOptions.key??pOptions.fileName;
    const vLang = pOptions.lang??(pOptions.fileName?pOptions.fileName.split('.').pop():"text");
    const vFileName = pOptions.fileName??pOptions.key;

  
    control.setModel(vKey,pOptions.modelValue,getLangFromExtension(vLang),vFileName);

    control.editor.onDidBlurEditorText(()=>{
        if (currentKey) {
            modelStates.set(currentKey,control?.editor.saveViewState());
        }
    });
    return control;
}

export class MonacoControl{
    editor:any;
   
    currentModel:string|null = null;
    readOnly:boolean = false;
    element:HTMLElement;
    lang:string|null=null;
    dataObject:any = null;
    keepModelsAfterDispose:boolean = false;

    get monaco(){
        return monaco;
    }

    get models(){
        return models;
    }

    get notKeepableModels(){
        return notKeepableModels;
    }
    get modelStates(){
        return modelStates;
    }

    get selectedText(){
        return this.editor.getModel()?.getValueInRange(this.editor.getSelection());
    }

    constructor(pOptions:any){
        this.element = pOptions.element
        if(pOptions.hasOwnProperty("readonly")) this.readOnly = pOptions['readonly'];
        if(pOptions.hasOwnProperty("lang")) this.lang = pOptions['lang'];
        if(pOptions.hasOwnProperty("dataObject")) this.dataObject = pOptions['dataObject'];
        if(pOptions.hasOwnProperty("keepModelsAfterDispose")) this.keepModelsAfterDispose = pOptions['keepModelsAfterDispose'];

        if(this.dataObject && !pOptions.hasOwnProperty("readonly")){
            this.readOnly = this.dataObject.allowUpdate;
        }
        
        this.initMonaco();

        if(this.dataObject){
            this.editor.addCommand(monaco.KeyMod.CtrlCmd | monaco.KeyCode.KeyS, () => {
                this.dataObject.save();    
            });
        }
    }

    openFind(pText:string) {
        const model = this.editor.getModel();

        if (model.findMatches(pText)[0]) {
            const range = model.findMatches(pText)[0].range;

            this.editor.setSelection(range);
            this.editor.revealLineInCenter(range.startLineNumber);
        }
        this.editor.getAction('actions.find').run();
    }

    moveToPosition = (pPos:any) => {
        this.editor.setScrollPosition({scrollTop: 0});
        this.editor.revealLine(pPos.startLineNumber);
        this.editor.revealLineInCenter(pPos.startLineNumber);
        let oldSelection = this.editor.getModel().selections;
        this.editor.setPosition({ column: pPos.startColumn, lineNumber: pPos.startLineNumber });
        this.editor.getModel().selections = oldSelection;
        this.editor.focus();
    }

    replaceRange = (pastePos:any,replacement:any) =>{
        const selection = this.editor.getSelection();
        const range = new monaco.Range(
            pastePos.startLineNumber || selection.endLineNumber,
            pastePos.startColumn || selection.endColumn,
            pastePos.endLineNumber || selection.endLineNumber,
            pastePos.endColumn || selection.endColumn,
        );
        this.editor.executeEdits( '', [ { range, text: replacement } ] );
    }

    setModelMarkers = (pMarkers:any) => {
        monaco.editor.setModelMarkers(this.editor.getModel(), "owner", pMarkers);
    }

    insertTextToCurrPos = (pText:string)=>{
        const vPosition = this.editor.getPosition();
        this.editor.executeEdits("",
            [
                {
                    range: new monaco.Range(
                        vPosition.lineNumber,
                        vPosition.column,
                        vPosition.lineNumber,
                        vPosition.column),
                        text: pText
                }
            ]
        );
    }

    addExtraLib = (filename:string, fileContent:string) => {
        const libUri = this._getExtraLibFilePath(filename);
        const typeScriptDefaults = [ 'javascriptDefaults', 'typescriptDefaults' ];

        for (const typeScriptDefault of typeScriptDefaults) {
            let extraLib = monaco.languages.typescript[typeScriptDefault].getExtraLibs()[libUri];
        
            if (extraLib === undefined) {
               /* const disposable = monaco.languages.typescript[typeScriptDefault].addExtraLib(fileContent, libUri);

                javaScriptExtraLib = monaco.languages.typescript[typeScriptDefault].getExtraLibs()[libUri];*/
            } else if (extraLib.content !== fileContent) {
                extraLib.content = fileContent;
            }
        }
    }

    removeModel = (pKey:string)=>{
        if(this.models.has(pKey))
            this.models.get(pKey).dispose();
        this.models.delete(pKey);
        this.modelStates.delete(pKey);
        
    }

    setModel = (pKey:string, pValue:string, pLang:string, _:string) => {    
        if (!pValue) pValue = "";
        if (!pKey) return;

        if(!this.models.has(pKey) && this.keepModelsAfterDispose){
            models.set(pKey,monaco.editor.createModel(pValue, pLang));
        }
         if(!this.notKeepableModels.has(pKey) && !this.keepModelsAfterDispose){
        
            notKeepableModels.set(pKey,monaco.editor.createModel(pValue, pLang));
        }

        if(this.keepModelsAfterDispose){
            this.editor.setModel(this.models.get(pKey));
        } else {
             this.editor.setModel(this.notKeepableModels.get(pKey));
        }
        if (this.modelStates.has(pKey)&& currentKey !== pKey) {
            this.editor.restoreViewState(this.modelStates.get(pKey));
        }

        if(pValue !== this.editor.getModel().getValue()) {
            this.editor.getModel().setValue(pValue);
        }

        currentKey = pKey;
    }

    private _getExtraLibFilePath = (fileName:string) => {
        return `file${fileName}`;
    }

    dispose(){
        if(!this.keepModelsAfterDispose){
           //monaco.editor.getModels().forEach((model: { dispose: () => any; }) => model.dispose());
            this.notKeepableModels.clear();
        }
        this.editor.dispose();
        control = null;
    }

    initMonaco(){       
        this.editor = monaco.editor.create(this.element, {
            readOnly: this.readOnly,
            dragAndDrop: true,
            // hover: { enabled: this.options&& vLanguage==="html"?false:true },
            minimap: false,
            dropIntoEditor:true,
            tabSize: 4,
            detectIndentation: false,
            automaticLayout: true
        });
        this.element.addEventListener("dragover",(e)=>this.dragOver(e));
        this.element.addEventListener("drop",(e)=>this.drop(e));
    }

    dragOver(e: DragEvent){
        e.preventDefault();
    }

    drop(e: DragEvent){
        
        if(e.dataTransfer?.getData("text"))
        this.insertTextToCurrPos(e.dataTransfer.getData("text"));
        e.preventDefault();
    }

}
